<script setup lang="ts">
import { FormField, ToggleButton } from '@laam/ui/base';
const emit = defineEmits<{
	(
		e: 'update-filter',
		value: {
			filterType: string;
			filterValue: string;
			source: string;
		},
	): void;
}>();
const logEvent = (name: string) => {
	emit('update-filter', {
		filterType: 'sort_by',
		filterValue: name,
		source: window.location.href,
	});
};
const sortFilters = ref([
	{
		name: 'Recommended',
		value: 'RECOMMENDED',
	},
	{
		name: 'Best Selling',
		value: 'BEST_SELLING',
	},
	{
		name: 'Newest First',
		value: 'DATE_NEW_TO_OLD',
	},
	{
		name: 'Price: Low to High',
		value: 'PRICE_LOW_TO_HIGH',
	},
	{
		name: 'Price: High to Low',
		value: 'PRICE_HIGH_TO_LOW',
	},
	// {
	// 	name: 'Discount: High to Low',
	// 	value: 'DISCOUNT_HIGH_TO_LOW',
	// },
	// {
	// 	name: 'Discount: Low to High',
	// 	value: 'DISCOUNT_LOW_TO_HIGH',
	// },
	// {
	// 	name: 'Delivery: Earliest to Latest',
	// 	value: 'DELIVERY_EARLIEST_TO_LATEST',
	// },
	// {
	// 	name: 'Delivery: Latest to Earliest',
	// 	value: 'DELIVERY_LATEST_TO_EARLIEST',
	// },
]);
</script>

<template>
	<div class="gap-xl flex flex-wrap">
		<FormField
			v-for="(item, index) in sortFilters"
			v-slot="{ handleChange, value }"
			:key="index"
			class="sort_filter_form"
			name="sort_by"
			type="radio"
		>
			<ToggleButton
				size="sm"
				class="font-medium capitalize"
				:checked="value?.includes(item.value)"
				@update:checked="
					() => {
						handleChange(item.value);
						logEvent(item.value);
					}
				"
			>
				{{ item.name }}
			</ToggleButton>
		</FormField>
	</div>
</template>
